<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="searchForm"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="所在道路" prop="pdaManagerName">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue1"
                  :clearable="true"
                  :fetch-suggestions="querySearchAsync1"
                  placeholder="请输入所在道路"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                  @input="changeEntryName"
                  @clear="handleClear"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_name')" prop="equId">
                <el-select
                  :disabled="!formInline.parkId"
                  v-model.trim="formInline.equId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value="">全部</el-option>
                  <el-option
                    :label="item.equipmentName"
                    :value="item.equipmentId"
                    v-for="(item, index) in equipmentList"
                    :key="index"
                    >{{ item.equipmentName }}</el-option
                  >
                </el-select>
                <!-- <el-input v-model.trim="formInline.equId" maxlength="20" placeholder="请输入内容" ></el-input> -->
              </el-form-item>
              <el-form-item :label="$t('searchModule.Alarm_type')" prop="faultType">
                <el-select v-model.trim="formInline.faultType" filterable size="15">
                  <el-option label="全部" value="">全部</el-option>
                  <el-option label="设备离线" value="0">设备离线</el-option>
                  <el-option label="镜头角度" value="1">镜头角度</el-option>
                  <el-option label="相机颜色" value="2">相机颜色</el-option>
                  <el-option label="镜头模糊" value="3">镜头模糊</el-option>
                  <el-option label="遮挡" value="4">遮挡</el-option>
                  <el-option label="泊位重叠" value="5">泊位重叠</el-option>
                  <el-option label="泊位划线问题" value="6">泊位划线问题</el-option>
                  <el-option label="其他" value="9">其他</el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')" prop="status">
                <el-select
                  v-model.trim="formInline.status"
                  filterable
                  placeholder="请输入内容"
                  size="12"
                >
                  <el-option label="全部" value="">全部</el-option>
                  <el-option label="待处理" value="0">待处理</el-option>
                  <el-option label="维修中" value="1">维修中</el-option>
                  <el-option label="已完成" value="3">已完成</el-option>
                  <el-option label="挂起" value="2">挂起</el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_manufacturer')" prop="brandId">
                <el-select v-model.trim="formInline.brandId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.brandFullName"
                    :value="value.brandId"
                    :key="value.brandId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.time_slot')" class="time_box">
                <el-date-picker
                  v-model="date1"
                  type="datetime"
                  :clearable="false"
                  placeholder="选择日期"
                  @change="showLog"
                >
                </el-date-picker>
                至
                <el-date-picker
                  v-model="date2"
                  type="datetime"
                  :clearable="false"
                  placeholder="选择日期"
                  @change="showLog"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  searchData();
                  page = 1;
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="searchClear">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.dispatch"></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.dispatch">
            <div class="col_left">
              <el-button
                type="success"
                @click="checkedSelectionChange()"
                v-if="$route.meta.authority.button.dispatch"
                >分配任务</el-button
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          min-height="400"
          @select="handleSelectionChange"
          @selection-change="checkedSelection"
        >
          <el-table-column
            v-if="$route.meta.authority.button.dispatch"
            type="selection"
            :selectable="selectable"
            width="55"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.detail"
                    >查看详情</el-dropdown-item
                  >
                  <el-dropdown-item command="b" v-if="$route.meta.authority.button.edit"
                    >更新进度</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <el-dialog
        title="分配维修任务"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-row style="margin: 30px">
          <el-col :span="24" style="text-align: center; font-size: 18px"
            >维修任务将分配给设备厂商智慧互通</el-col
          >
        </el-row>
        <el-row style="margin-top: 50px">
          <el-col :span="12" style="text-align: center">
            <el-button type="primary" plain @click="dialogVisible = false" style="width: 100px"
              >关闭</el-button
            >
          </el-col>
          <el-col :span="12" style="text-align: center">
            <el-button type="primary" style="width: 100px" @click="distribution()"
              >分配任务</el-button
            >
          </el-col>
        </el-row>
      </el-dialog>

      <el-dialog
        title="更新维修进度"
        :visible.sync="dialogIsshow"
        width="30%"
        :before-close="handleClose"
      >
        <el-row>
          <el-col :span="6" style="line-height: 40px; text-align: center" class="title-box">
            维修进度
          </el-col>
          <el-col :span="18">
            <el-select v-model.trim="formInline.Repair" @change="RepairChange()" size="15">
              <el-option label="挂起" value="2">挂起</el-option>
              <el-option label="已完成" value="3">已完成</el-option>
            </el-select>
          </el-col>
        </el-row>
        <br />
        <el-row>
          <el-col :span="6" style="line-height: 40px; text-align: center">
            <span>{{ stateText ? stateText : "挂起原因" }}</span>
          </el-col>
          <el-col :span="18">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
              maxlength="140"
              v-model="textarea2"
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row style="text-align: center; margin-top: 30px">
          <el-col :span="12">
            <el-button @click="dialogIsshow = false" style="width: 100px">取消</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" style="width: 100px" @click="update">更新进度</el-button>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import picDetail from "../../../components/picDetail/picDetail";
import { dateFormat } from "../../../common/js/public.js";
//    import InfoList from '../../../components/infoList/infoList'
//    import 'viewerjs/dist/viewer.css'
let date1 = new Date();
date1.setHours(0);
date1.setMinutes(0);
date1.setSeconds(0);
// let startTime = date1;

let date2 = new Date();
date2.setHours(23);
date2.setMinutes(59);
date2.setSeconds(59);
export default {
  name: "deviceAlarm",
  data() {
    // let endTime = date;
    return {
      tenantList: [],
      equipmentList: [],
      modelvalue1: "",
      dialogVisible: false,
      dialogIsshow: false,
      stateText: "",
      textarea2: "",
      resourse: [],
      plateNumber: "",
      total: 0,
      date1: date1,
      date2: date2,
      pageSize: 15,
      page: 1,
      loading: false,
      multipleSelection: [],
      // tableData: [{
      //     plateNumber: '123',
      //     illegalParkType: 1,
      //     captureTime: '泊车',
      //     states: true,
      //     sta: 6
      // }],
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "equName",
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "brandName",
          label: "设备品牌",
          width: "",
        },
        {
          prop: "faultTypeName",
          label: "报警类型",
          width: "",
        },
        {
          prop: "faultTime",
          label: "报警时间",
          width: "",
        },
        {
          prop: "reportPeopleName",
          label: "报修人员",
          width: "",
        },
        {
          prop: "status",
          label: "当前状态",
          width: "",
          formatter: (row) => {
            if (row.status == 0) {
              return "待处理";
            } else if (row.status == 1) {
              return "维修中";
            } else if (row.status == 2) {
              return "挂起";
            } else if (row.status == 3) {
              return "已完成";
            }
          },
        },
        {
          prop: "brandContact",
          label: "联系人",
          width: "",
        },
        {
          prop: "endTime",
          label: "结束时间",
          width: "170",
        },
      ],
      // dateRange: [+new Date(startTime), +new Date(endTime)],
      formInline: {
        startTime: date1,
        endTime: date2,
        parkId: "",
        equId: "",
        brandId: "",
        faultType: "",
        status: "",
      },
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
    dialogIsshow(newVal, oldVal) {
      if (!newVal) {
        this.formInline.Repair = "";
        this.textarea2 = "";
      }
    },
  },
  methods: {
    // 设备厂商接口 /acb/2.0/brand/selectList?operationId=1654401553572843522接口
    getTenantList() {
      this.$axios.get("/acb/2.0/brand/selectList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    searchClear() {
      this.modelvalue1 = "";
      this.equipmentList = [];
      this.$refs.searchForm.resetFields();
      this.formInline.startTime = date1;
      this.formInline.endTime = date2;
      this.date1 = date1;
      this.date2 = date2;
    },
    // 分配任务按钮
    distribution() {
      let arr = [];
      this.multipleSelection.forEach((element) => {
        arr.push(element.equFaultId);
      });
      let str = arr.join();
      this.$axios
        .post("/acb/2.0/areaEquFault/updateBrand", {
          data: {
            equFaultIds: str,
          },
        })
        .then((res) => {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.dialogIsshow = false;
        });
    },
    // 获取选中复选框的值
    checkedSelectionChange() {
      let arr = this.multipleSelection;
      if (arr.length <= 0) {
        this.$alert("请选择！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      } else {
        this.dialogVisible = true;
      }
    },
    checkedSelection(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange(selection, row) {
      if (this.selectFlag) {
        this.selectFlag = false;
        return this.selectFlag;
      }
      if (selection.length > 1) return;
      if (selection.length == 0) {
        this.tableData = this.tableData.map((val) => {
          val.isAbled = true;
          return val;
        });
      } else {
        if (selection[0].equFaultId != row.equFaultId) return;
        let col = [];
        col = this.tableData.map((val) => {
          val.isAbled = row.brandName == val.brandName;
          return val;
        });
        this.tableData = col;
        let r = this.tableData.filter((element) => {
          return element.equFaultId == row.equFaultId;
        })[0];
        this.selectFlag = true;
        setTimeout(() => this.$refs.multipleTable.toggleRowSelection(r, true));
      }
    },
    selectable(row, index) {
      // if (row.status == 1) {
      //   return false
      // } else
      if (typeof row.isAbled == "undefined" || row.isAbled) {
        return true;
      } else {
        return false;
      }
    },
    handleClear() {
      this.formInline.equId = "";
      this.formInline.parkId = "";
    },
    changeEntryName(val) {
      if (val && val.parkId) {
      } else {
        this.formInline.equId = "";
        this.formInline.parkId = "";
      }
    },
    handleSelect(item) {
      if (item && item.parkId) {
        this.formInline.equId = "";
        this.formInline.parkId = item.parkId;
        // 请求车场下的设备
        this.$axios
          .get(`/acb/2.0/equipment/equipmentsByParkId/${item.parkId}`, {
            data: {
              page: 0,
              pageSize: 0,
            },
          })
          .then((res) => {
            this.equipmentList = res.value.list || [];
          })
          .catch(() => {
            this.equipmentList = [];
          });
      } else {
        this.formInline.equId = "";
        this.formInline.parkId = "";
      }
    },
    querySearchAsync1(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: this.modelvalue1,
            slaveRelations: "0,2",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            //  this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    RepairChange() {
      if (this.formInline.Repair == "2") {
        this.stateText = "挂起原因";
      } else {
        this.stateText = "故障原因";
      }
    },
    update() {
      if (!this.formInline.Repair) {
        this.$alert("请选择维修进度", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.$axios
        .post("/acb/2.0/areaEquFault/update", {
          data: {
            equFaultId: this.formInline.equFaultId,
            status: this.formInline.Repair,
            faultReason: this.textarea2,
          },
        })
        .then((res) => {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.searchData();
          this.dialogIsshow = false;
        });
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$router.push({ path: "/deviceAlarmDetail", query: data });
      }
      if (cmd == "b") {
        this.dialogIsshow = true;
        this.formInline.equFaultId = data.equFaultId;
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    indexMethod(index) {
      return index + 1;
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    showLog() {
      if (!this.date1 || !this.date2) {
        this.$alert("开始、结束时间不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.date1 && this.date2) {
        let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
        // this.formInline.startTime = startTime || this.date1;
        // this.formInline.endTime = endTime || this.date2;
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
        let diff =
          new Date(this.formInline.startTime).getTime() / 1000 -
          new Date(this.formInline.endTime).getTime() / 1000;
        if (diff > 0) {
          this.$alert("开始日期不能大于结束日期", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        } else if (0 - diff > 30 * 24 * 60 * 60 && this.formInline.plateNumber == "") {
          this.$alert("只能查询30天记录", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("时间段不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 搜索
    searchData() {
      if (this.modelvalue1 == "") {
        this.formInline.parkId = "";
      }
      let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
      let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
      this.formInline.startTime = startTime;
      this.formInline.endTime = endTime;
      let url = "/acb/2.0/areaEquFault/list";
      this.$axios
        .get(url, {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {
    //      InfoList,
    //      picDetail
  },
  created() {},
  mounted() {
    this.searchData();
    this.getTenantList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.time_box /deep/ .el-input__inner
  min-width:220px;
.time_box /deep/ .el-input__prefix
  left:0
.content
  overflow: hidden;
  .searchWrapper
    overflow: hidden;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  .pagerWrapper
    text-align: right;
    margin-top: 18px;
    font-size: 12px;
/deep/ {
  .title-box {
    position: relative;
    &::before {
      content: '*';
      position: absolute;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
  color: #F56C6C;
  // margin-right: 4px;
    }
  }
}
</style>
<style scoped>
.parkTitle {
  border-bottom: 1px solid #e1e1e1;
  transform: translateY(-50%);
}
.parkTitle span {
  text-align: center;
  display: inline-block;
  width: 100px;
  line-height: 30px;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
}
.pic {
  margin-top: 20px;
}
.pic img {
  width: 200px;
  height: 200px;
}
.picTitle {
  line-height: 30px;
}
.detail1 {
  line-height: 25px;
}
</style>
